<template>
    <div class="o-layout o-layout--large">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <recess-button
                        :title="BUTTON_TEXT.goBack"
                        icon="back"
                        variant="variant4"
                        class="qa-go-back-button"
                        @click="$router.go(-1)"
                    />
                     
                    <title-text
                        :title="PROVIDER.Order.OrderOverview.Title"
                        class="qa-order-title"
                    />
                </div>
                <div class="col-12 mt-3">
                    <recess-card variant="variant1">
                        <div class="container-fluid">
                            <div class="row justify-content-between align-items-end">
                                <div class="col-4">
                                    <recess-multi-select
                                        :selected-options="selectedFilters.orderStatus"
                                        :options="orderStatus"
                                        :single-select="true"
                                        :label-text="PROVIDER.Order.OrderOverview.OrderStatus.Label"
                                        class="qa-order-status-select"
                                        :select-option-text="PROVIDER.Order.OrderOverview.OrderStatus.Placeholder"
                                        @input="(newValue) =>setSpecificFilter('orderStatus', newValue)"
                                    >
                                        <template slot="customIcon">
                                            <recess-icon
                                                class="qa-open-order-status-modal"
                                                icon="info"
                                                color="secondary"
                                                cursor="pointer"
                                                @click="showOrderStatusModal"
                                            />
                                        </template>
                                    </recess-multi-select>
                                </div>
                                <div class="col-4">
                                    <recess-multi-select
                                        :selected-options="selectedFilters.orderType"
                                        :options="orderTypeOptions"
                                        :single-select="true"
                                        :label-text="PROVIDER.Order.OrderOverview.OrderType.Label"
                                        class="qa-order-type-select"
                                        :select-option-text="selectedFilters.orderType.displayText"
                                        @input="(newValue) => setSpecificFilter('orderType', newValue)"
                                    >
                                    </recess-multi-select>
                                </div>
                                <div class="col-4">
                                    <recess-search-input
                                        v-model.trim="selectedFilters.searchQuery"
                                        :placeholder="PROVIDER.Order.OrderOverview.SearchQuery.Placeholder"
                                        class="qa-order-search-input" 
                                        @input="(newValue) => setSpecificFilter('searchQuery', newValue)"
                                    />
                                </div>
                            </div>
                        </div>
                    </recess-card>
                </div>
            </div>

            <div class="row">
                <order-list 
                    class="col-12" 
                    :set-selected-filters="localFilters"
                    :is-order-overview="true"
                />
            </div>
        </div>
        <order-status-popup-component 
            :is-modal-visible="isOrderStatusModalVisible"
            @close="closeOrderStatusModal"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { getEnumList } from '@/../../shared/api/SharedClient'
import TitleText from '@/components/atoms/TitleText'
import OrderList from '@/components/organisms/Order/OrderList'
import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import UserService from '../../../../shared/services/UserService'
import OrderStatusPopupComponent from '../../components/molecules/OrderStatusPopupComponent.vue'

import filterMixin from '@/../../shared/mixins/filterMixin'

export default {
    name: 'OrderOverview',
    components: {
        TitleText,
        OrderList,
        OrderStatusPopupComponent
    },
    mixins: [filterMixin],
    data() {
        return {
            userService: new UserService(),
            isAdministrator: false,
            BUTTON_TEXT,
            PROVIDER,
            orderStatus: [{
                displayText: PROVIDER.Order.OrderOverview.SelectOrderStatus.AllOrders,
                value: 'All'
            }],
            orderType: [{
                displayText: PROVIDER.Order.OrderOverview.OrderType.Course,
                value: 'Course'
            }],
            orderTypeOptions: [
                { displayText: PROVIDER.Order.OrderOverview.OrderType.Course, value: 'Course' },
                { displayText: PROVIDER.Order.OrderOverview.OrderType.PurchaseAgreement, value: 'PurchaseAgreement'},
                { displayText: PROVIDER.Order.OrderOverview.OrderType.AllOrders, value: 'All'}
            ],
            selectedFilters: {
                orderStatus: PROVIDER.Order.OrderOverview.DefaultOrderStatus,
                orderType: PROVIDER.Order.OrderOverview.DefaultOrderType,
                searchQuery: '',
                providerId: localStorage.getItem('providerId')
            },
            isOrderStatusModalVisible:false
        }
    },
    computed: {
        ...mapState('providerModule', ['selectedProvider'])
    },
    watch: {
        selectedProvider: {
            handler() {
                if (this.selectedProvider && this.selectedFilters && this.selectedProvider.id !== this.selectedFilters.providerId) {
                    this.setSpecificFilter('providerId', this.selectedProvider.id)
                }
            },
            deep: true
        }
    },
    async created() {
        this.isAdministrator = await this.userService.isAdministrator() 
    },
    mounted() {
        this.$nextTick(() => {
            this.getOrderStatus() 
        })
    },
    methods: {
        showOrderStatusModal() {
            this.isOrderStatusModalVisible = true
        },
        closeOrderStatusModal() {
            this.isOrderStatusModalVisible = false
        }, 
        async getOrderStatus() {
            try {
                let response = await getEnumList(
                    process.env.VUE_APP_ORDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'orderStatus',
                    null,
                    null,
                    false
                )
                if (!response) return

                if (!this.isAdministrator) {
                   response = response.filter(x => x.value !== 'Invalid' && x.value !== 'PendingValidation')
                } 
                
                this.orderStatus = [...this.orderStatus, ...response]
                
            } catch (error) {
                console.error('Something went wrong while retrieving order status enum list', error)
            }
        }
    }
}
</script>
